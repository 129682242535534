import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import './styles.css';

function Header() {

  const [isDropdownVisible, setDropdownVisible] = useState(false);

  const [isLoggedIn, setIsLoggedIn] = useState(false);


  const dropdownRef = useRef(null);
  const buttonRef = useRef(null);


  const toggleDropdown = () => {
    setDropdownVisible((prev) => !prev);
  };

 
  const [isAccountDropdownVisible, setAccountDropdownVisible] = useState(false);


  const accountDropdownRef = useRef(null);
  const accountButtonRef = useRef(null);

  const handleLoginLogout = () => {
    if (isLoggedIn) {
      setIsLoggedIn(false);
      setAccountDropdownVisible(false); 
    } else {
      setIsLoggedIn(true);
    }
  };

  
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        isDropdownVisible &&
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target) &&
        buttonRef.current &&
        !buttonRef.current.contains(event.target)
      ) {
        setDropdownVisible(false);
      }

      if (
        isAccountDropdownVisible &&
        accountDropdownRef.current &&
        !accountDropdownRef.current.contains(event.target) &&
        accountButtonRef.current &&
        !accountButtonRef.current.contains(event.target)
      ) {
        setAccountDropdownVisible(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

  
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isDropdownVisible, isAccountDropdownVisible]);

  return (
    <div>


      <header className="bg-base-300 shadow-md">
        <div className="container mx-auto flex items-center justify-between">
          <div className="flex items-center">
            {/* Logo and company name */}
            <img
              src="/Gen6-512.png"
              alt="Logo"
              className="mr-2"
              style={{ maxWidth: '70px', maxHeight: '70px' }}
            />
            <div className="text-2xl text-primary blue">
              <a href="https://malachi.technology">Malachi Technologies, LLC</a>
            </div>
          </div>

          <div className="navbar-end flex items-center">
            <nav className="flex items-center space-x-2">

              {/* Innovation Hub with Coming Soon stamp */}
              <div className="relative">
                <button className="btn btn-ghost btn-sm" disabled>
                  Innovation Hub
                </button>
                {/* Coming Soon Overlay */}
                <div className="absolute inset-0 flex items-center justify-center bg-opacity-75 bg-gray-800 text-white font-bold text-lg">
                  Coming Soon
                </div>
              </div>

              {/* Custom Help Desk dropdown toggle */}
              <div className="relative" style={{ zIndex: 1 }}>
                <button
                  ref={buttonRef}
                  className="btn btn-ghost btn-sm"
                  onClick={toggleDropdown}
                >
                  Help Desk
                </button>
                {isDropdownVisible && (
                  <ul
                    ref={dropdownRef}
                    className="menu menu-dropdown absolute right-0 mt-2 rounded-box bg-base-100"
                    style={{ zIndex: 2, minWidth: '150px' }}
                  >
                    <li>
                      <a href="https://help.malachi.technology/index.php">
                        Help Portal
                      </a>
                    </li>
                    <li>
                      <a href="https://help.malachi.technology/index.php?a=add">
                        Submit a Ticket
                      </a>
                    </li>
                  </ul>
                )}
              </div>

              <a
                className="btn btn-ghost btn-sm"
                href="https://uptime.malachi.technology"
              >
                Service Status
              </a>
              <Link className="btn btn-ghost btn-sm" to="/Product">
                Products
              </Link>
              <Link className="btn btn-ghost btn-sm" to="/about">
                About
              </Link>

              {/* Login/Account Button */}
              <div className="ml-2">
                {isLoggedIn ? (
                  <div className="relative ml-2" style={{ zIndex: 1 }}>
                    <button
                      ref={accountButtonRef}
                      className="btn btn-ghost btn-sm relative"
                      disabled
                    >
                      My Account
                    </button>
                    {/* Coming Soon Overlay */}
                    <div className="absolute inset-0 flex items-center justify-center bg-opacity-75 bg-gray-800 text-white font-bold text-lg">
                      Coming Soon
                    </div>
                  </div>
                ) : (
                  // If user is not logged in, show Login button
                  <button
                    className="btn btn-primary btn-sm ml-2"
                    onClick={handleLoginLogout}
                  >
                    Login
                  </button>
                )}
              </div>

            </nav>
          </div>
        </div>
      </header>
    </div>
  );
}

export default Header;
