import React from 'react';
import './Product.css';
import Header from '../components/Header.js';
import Footer from '../components/Footer.js';
import webHostingIcon from './icons8-web-hosting-48.png'; 

const Product = () => {
  // Array of products
  const products = [
    {
      id: 1,
      name: "AI (Amadeus)",
      description: "Large Language Models (text generation)",
      price: "$5-$10/MO",
      imageUrl: webHostingIcon,
    },
    {
      id: 2,
      name: "Cloud Compute (Hyve)",
      description: "VPS and Server for hosting and computing.",
      price: "Price Per Spec",
      imageUrl: webHostingIcon,
    },
    {
      id: 3,
      name: "IT Consulting",
      description: "assisting small businesses with setting up the tech they need to get started",
      price: "Contact for pricing",
      imageUrl: webHostingIcon,
    },
    {
      id: 4,
      name: "Software as a Service",
      description: "Tools For ecommerce, productivity, and more",
      price: "Prices TBD (User/Mo)",
      imageUrl: webHostingIcon,
    },
    {
      id: 5,
      name: "Web Hosting (Hyve)",
      description: "Webhosting for small businesses and personal websites using Hyve",
      price: "Prices TBD",
      imageUrl: webHostingIcon,
    },
  ];

  return (
    <div>
      <Header />
      {/* Main container with padding for spacing between header and footer */}
      <div className="products-container mx-auto my-20 p-6">
        <div className="grid gap-10 grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
          {/* Map through the array of products and render each one in its own card */}
          {products.map((product) => (
            <div key={product.id} className="product-card p-6 bg-base-100 shadow-xl rounded-lg">
              {/* Product Image */}
              <div className="product-image-container text-center mb-4">
                <img
                  src={product.imageUrl}
                  alt={product.name}
                   className="product-image mx-auto max-w-[48px] max-h-[48px]"
                />
              </div>

              {/* Product Info */}
              <div className="product-info text-center">
                <h1 className="product-name text-2xl font-bold mb-4">{product.name}</h1>
                <p className="product-description text-neutral-content text-md mb-4">
                  {product.description}
                </p>
                <div className="product-price text-xl font-semibold text-primary mb-4">
                  {product.price}
                </div>
              </div>

              {/* Action Button */}
              <div className="product-action text-center">
                <button className="btn btn-primary px-6 py-1 text-lg rounded-full disabled">
                  Coming Soon
                </button>
              </div>
            </div>
          ))}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Product;

