import React, { useState, useEffect, useRef } from 'react';
import './styles.css';

function LessonsLearned() {
  const [collapsed, setCollapsed] = useState({
    title: false,
    paragraph: false,
    card1: false,
    card2: false,
    card3: false,
    card4: false,
  });

  const titleRef = useRef(null);
  const paragraphRef = useRef(null);
  const cardRefs = useRef([
    React.createRef(),
    React.createRef(),
    React.createRef(),
    React.createRef(),
  ]);

  useEffect(() => {
    const options = {
      root: null,
      threshold: 0,
    };

    const handleIntersect = (entries) => {
      entries.forEach((entry) => {
        const name = entry.target.getAttribute('data-name');
        setCollapsed((prev) => ({ ...prev, [name]: !entry.isIntersecting }));
      });
    };

    const observer = new IntersectionObserver(handleIntersect, options);

    const elements = [
      { ref: titleRef, name: 'title' },
      { ref: paragraphRef, name: 'paragraph' },
      { ref: cardRefs.current[0], name: 'card1' },
      { ref: cardRefs.current[1], name: 'card2' },
      { ref: cardRefs.current[2], name: 'card3' },
      { ref: cardRefs.current[3], name: 'card4' },
    ];

    elements.forEach(({ ref, name }) => {
      if (ref.current) {
        ref.current.setAttribute('data-name', name);
        observer.observe(ref.current);
      }
    });

    return () => {
      observer.disconnect();
    };
  }, []);

  return (
    <div className="bg-base-200 min-h-screen flex items-center">
      <div className="container mx-auto">
        <div className="flex-col lg:flex-row">
          <h1
            ref={titleRef}
            className={`text-5xl font-bold ${
              collapsed.title ? 'collapse' : 'expand'
            }`}
          >
            Lessons from Hive
          </h1>
          <p
            ref={paragraphRef}
            className={`py-6 ${collapsed.paragraph ? 'collapse' : 'expand'}`}
          >
            Our experience with Hive has provided us with valuable insights into server technology and project management. Here are some key lessons we've learned:
          </p>
        </div>
        <div className="flex flex-wrap justify-between">
          <div
            ref={cardRefs.current[0]}
            className={`card w-96 bg-base-100 shadow-xl mt-6 ${
              collapsed.card1 ? 'collapse' : 'expand'
            }`}
          >
            <div className="card-body">
              <h2 className="card-title">Innovation Drives Progress</h2>
              <p>
                Embracing new technologies and ideas is essential for creating groundbreaking solutions.
              </p>
            </div>
          </div>
          <div
            ref={cardRefs.current[1]}
            className={`card w-96 bg-base-100 shadow-xl mt-6 ${
              collapsed.card2 ? 'collapse' : 'expand'
            }`}
          >
            <div className="card-body">
              <h2 className="card-title">Teamwork Matters</h2>
              <p>
                Collaborative efforts lead to better outcomes and foster a culture of shared success.
              </p>
            </div>
          </div>
          <div
            ref={cardRefs.current[2]}
            className={`card w-96 bg-base-100 shadow-xl mt-6 ${
              collapsed.card3 ? 'collapse' : 'expand'
            }`}
          >
            <div className="card-body">
              <h2 className="card-title">Adaptability is Key</h2>
              <p>
                Being flexible and adaptable allows us to overcome challenges and adjust to changing needs.
              </p>
            </div>
          </div>
          <div
            ref={cardRefs.current[3]}
            className={`card w-96 bg-base-100 shadow-xl mt-6 ${
              collapsed.card4 ? 'collapse' : 'expand'}`}
          >
            <div className="card-body">
              <h2 className="card-title">Commitment to Quality</h2>
              <p>
                Maintaining high standards ensures that we deliver reliable and effective solutions.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LessonsLearned;
