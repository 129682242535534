import './App.css';
import Header from './components/Header.js';
import Footer from './components/Footer.js';
import Hero01 from './AppJS/Hero01.js';
import Hero02 from './AppJS/Hero02.js';
import LessonsLearned from './AppJS/LessonsLearned.js';

function App() {
  return (
    <div className="App font-figtree">
      <header>
        <Header />
      </header>
      <main>
        {/* First Hero Section */}
        <div
          className="hero min-h-screen"
          style={{ backgroundImage: 'url(/mtech-bg.png)' }}
        >
          <div className="hero-overlay bg-opacity-10 blur"></div>
          <div className="hero-content text-left text-neutral-content text-blue">
            <div className="max-w-md">
              <h1 className="mb-5 text-6xl font-bold">Introducing Hive</h1>
              <p className="mb-5 text-2xl">
                Welcome to Hive, our upcoming server designed to push the boundaries of performance and innovation. Join us on this exciting journey as we explore the future of technology with Hive.
              </p>
              <a href="/Product" className="btn btn-primary">
        View Our Future Products
      </a>
            </div>
          </div>
        </div>

        {/* Hero01 Component */}
        <Hero01 />

        {/* Hero02 Component */}
        <Hero02 />

        {/* Lessons Section */}
        <LessonsLearned />
      </main>
      <footer>
        <Footer />
      </footer>
    </div>
  );
}

export default App;

