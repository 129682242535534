import React from 'react';
import './styles.css';
import Header from '../components/Header.js';
import Footer from '../components/Footer.js';

function About() {
  return (
    <div className="About">
      <Header />
      <main>
        {/* Hero Section */}
        <section className="hero bg-gradient-to-r from-blue-500 to-teal-500 text-white py-20">
          <div className="container mx-auto px-6 text-center">
            <h1 className="text-5xl font-bold mb-4">Transforming Small Businesses with Innovative Tech Solutions</h1>
            <p className="text-xl max-w-3xl mx-auto">
              We are your partners in success, providing cutting-edge technology solutions that propel your business forward.
            </p>
          </div>
        </section>

        {/* Our Journey Section */}
        <section className="py-16 bg-white">
          <div className="container mx-auto px-6">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-12 items-center">
              <div>
                <h2 className="text-4xl font-bold mb-6">Our Journey</h2>
                <p className="text-lg leading-7 mb-4">
                  From humble beginnings to becoming a trusted tech partner for small businesses nationwide, our journey is rooted in a passion for innovation and a commitment to excellence.
                </p>
                <p className="text-lg leading-7 mb-4">
                  We started with a simple idea: to make advanced technology accessible and affordable for businesses of all sizes. Over the years, we've grown into a one-stop shop for all tech needs, offering personalized solutions that drive real results.
                </p>
                <p className="text-lg leading-7">
                  Our team of experts is dedicated to staying ahead of industry trends, ensuring that our clients benefit from the latest advancements in technology.
                </p>
              </div>
              <div>
                <img
                  src={require('../AboutJS/our_journey_tech_website.png')}
                  alt="Our Journey"
                  className="rounded-lg shadow-lg w-full"
                />
              </div>
            </div>
          </div>
        </section>

        {/* Innovation Spotlight Section */}
        <section className="py-16 bg-gray-100">
          <div className="container mx-auto px-6">
            <h2 className="text-4xl font-bold text-center mb-12">Innovation at the Core</h2>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
              {/* Feature 1 */}
              <div className="bg-white p-6 rounded-lg shadow-lg hover:shadow-xl transition-shadow duration-300">
                <div className="mb-4">
                  <img
                    src={require('../AboutJS/Cloud_Solutions.png')}
                    alt="Cloud Solutions"
                    className="w-full h-40 object-cover rounded-md"
                  />
                </div>
                <h3 className="text-2xl font-semibold mb-2">Cloud Solutions</h3>
                <p className="text-lg leading-7">
                  Seamlessly migrate to the cloud with our secure and scalable solutions, designed to enhance flexibility and reduce costs.
                </p>
              </div>
              {/* Feature 2 */}
              <div className="bg-white p-6 rounded-lg shadow-lg hover:shadow-xl transition-shadow duration-300">
                <div className="mb-4">
                  <img
                    src={require('../AboutJS/AI_Integrations.png')}
                    alt="AI Integration"
                    className="w-full h-40 object-cover rounded-md"
                  />
                </div>
                <h3 className="text-2xl font-semibold mb-2">AI Integration</h3>
                <p className="text-lg leading-7">
                  Leverage artificial intelligence to gain insights, automate processes, and stay ahead of the competition.
                </p>
              </div>
              {/* Feature 3 */}
              <div className="bg-white p-6 rounded-lg shadow-lg hover:shadow-xl transition-shadow duration-300">
                <div className="mb-4">
                  <img
                    src={require('../AboutJS/Cybersecurity (1).png')}
                    alt="Cybersecurity"
                    className="w-full h-40 object-cover rounded-md"
                  />
                </div>
                <h3 className="text-2xl font-semibold mb-2">Cybersecurity</h3>
                <p className="text-lg leading-7">
                  Protect your business with our comprehensive cybersecurity services, safeguarding your data and reputation.
                </p>
              </div>
            </div>
          </div>
        </section>

        {/* Interactive Timeline Section */}
        <section className="py-16 bg-white">
          <div className="container mx-auto px-6">
            <h2 className="text-4xl font-bold text-center mb-12">Our Milestones</h2>
            <div className="relative">
              {/* Vertical Line */}
              <div className="absolute left-1/2 transform -translate-x-1/2 h-full border-l-2 border-gray-200"></div>
              {/* Timeline Items */}
              <div className="space-y-12">
                {/* Item 1 */}
                <div className="flex items-center">
                  <div className="flex-1 pr-8 text-right">
                    <h3 className="text-2xl font-semibold">January 2024</h3>
                    <p className="mt-2 text-lg leading-7">
                      Launched of our company, and future products.
                    </p>
                  </div>
                  <div className="w-8 h-8 bg-blue-500 rounded-full flex items-center justify-center text-white">
                    <i className="fas fa-cloud"></i>
                  </div>
                  <div className="flex-1"></div>
                </div>
                {/* Item 2 */}
                <div className="flex items-center">
                  <div className="flex-1"></div>
                  <div className="w-8 h-8 bg-teal-500 rounded-full flex items-center justify-center text-white">
                    <i className="fas fa-robot"></i>
                  </div>
                  <div className="flex-1 pl-8">
                    <h3 className="text-2xl font-semibold">June 2024</h3>
                    <p className="mt-2 text-lg leading-7">
                      Introduced AI-driven analytics, providing clients with actionable insights and predictive capabilities.
                    </p>
                  </div>
                </div>
                {/* Item 3 */}
                <div className="flex items-center">
                  <div className="flex-1 pr-8 text-right">
                    <h3 className="text-2xl font-semibold">August 2024</h3>
                    <p className="mt-2 text-lg leading-7">
                      Expanded our cybersecurity staff as well as our development team to meet growing demand for our services.
                    </p>
                  </div>
                  <div className="w-8 h-8 bg-purple-500 rounded-full flex items-center justify-center text-white">
                    <i className="fas fa-shield-alt"></i>
                  </div>
                  <div className="flex-1"></div>
                </div>
                {/* Item 4 */}
                <div className="flex items-center">
                  <div className="flex-1"></div>
                  <div className="w-8 h-8 bg-pink-500 rounded-full flex items-center justify-center text-white">
                    <i className="fas fa-award"></i>
                  </div>
                  <div className="flex-1 pl-8">
                    <h3 className="text-2xl font-semibold">Future (2025)</h3>
                    <p className="mt-2 text-lg leading-7">
                      Launch of our products and other future opportunities.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* Client Success Stories Section */}
        <section className="py-16 bg-gray-100">
          <div className="container mx-auto px-6">
            <h2 className="text-4xl font-bold text-center mb-12">Client Success Stories</h2>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
              {/* Story 1 */}
              <div className="bg-white p-6 rounded-lg shadow-lg">
                <h3 className="text-2xl font-semibold mb-4">Boosting Efficiency for Speakeasy Services</h3>
                <p className="text-lg leading-7 mb-4">
                  Implemented a custom cloud solution that increased operational efficiency by 40%, allowing Speakeasy Services to scale rapidly.
                </p>
                <button className="text-blue-500 font-semibold hover:underline cursor-default" disabled>
                  Read More &rarr;
                </button>
              </div>
              {/* Story 2 */}
              <div className="bg-white p-6 rounded-lg shadow-lg">
                <h3 className="text-2xl font-semibold mb-4">Transforming Data Analytics at  CCYSA Services</h3>
                <p className="text-lg leading-7 mb-4">
                  Integrated AI analytics tools that provided deep insights with Hyve than other standard server sites.
                </p>
                <button className="text-blue-500 font-semibold hover:underline cursor-default" disabled>
                  Read More &rarr;
                </button>
              </div>
            </div>
          </div>
        </section>

        {/* Contact CTA Section */}
        <section className="py-16 bg-gradient-to-r from-teal-500 to-blue-500 text-white">
          <div className="container mx-auto px-6 text-center">
            <h2 className="text-4xl font-bold mb-4">Let's Innovate Together</h2>
            <p className="text-xl mb-8">
              Ready to take your business to the next level? Contact us today to discover how we can empower your success.
            </p>
            <a href="/Product" className="btn bg-white text-teal-500 hover:text-white hover:bg-teal-600 px-8 py-2 text-lg font-semibold rounded-full">
              Get Started
            </a>
          </div>
        </section>
      </main>
      <Footer />
    </div>
  );
}

export default About;
