import React, { useState, useEffect, useRef } from 'react';
import './styles.css';

function Hero01() {
  const [collapsed, setCollapsed] = useState({
    title: false,
    paragraph: false,
    card1: false,
    card2: false,
    card3: false,
  });

  const titleRef = useRef(null);
  const paragraphRef = useRef(null);
  const card1Ref = useRef(null);
  const card2Ref = useRef(null);
  const card3Ref = useRef(null);

  useEffect(() => {
    const options = {
      root: null,
      threshold: 0,
    };

    const handleIntersect = (entries) => {
      entries.forEach((entry) => {
        const name = entry.target.getAttribute('data-name');
        setCollapsed((prev) => ({ ...prev, [name]: !entry.isIntersecting }));
      });
    };

    const observer = new IntersectionObserver(handleIntersect, options);

    const elements = [
      { ref: titleRef, name: 'title' },
      { ref: paragraphRef, name: 'paragraph' },
      { ref: card1Ref, name: 'card1' },
      { ref: card2Ref, name: 'card2' },
      { ref: card3Ref, name: 'card3' },
    ];

    elements.forEach(({ ref, name }) => {
      if (ref.current) {
        ref.current.setAttribute('data-name', name);
        observer.observe(ref.current);
      }
    });

    return () => {
      observer.disconnect();
    };
  }, []);

  return (
    <div className="Hero01 min-h-screen flex items-center">
      <div className="container mx-auto">
        <div className="hero-content flex-col lg:flex-row">
          <h1
            ref={titleRef}
            className={`text-5xl font-bold ${
              collapsed.title ? 'collapse' : 'expand'
            }`}
          >
            Discover Hive
          </h1>
          <p
            ref={paragraphRef}
            className={`py-6 ${collapsed.paragraph ? 'collapse' : 'expand'}`}
          >
            Hive is our state-of-the-art server platform designed to deliver unmatched performance, scalability, and reliability. It's the culmination of years of innovation and expertise in server technology.
          </p>
        </div>
        <div className="flex flex-wrap justify-between">
          <div
            ref={card1Ref}
            className={`card w-96 bg-base-200 shadow-xl mt-6 ${
              collapsed.card1 ? 'collapse' : 'expand'
            }`}
          >
            <div className="card-body">
              <h2 className="card-title">Cutting-Edge Technology</h2>
              <p>
                Hive incorporates the latest advancements in hardware and software to ensure peak performance and efficiency.
              </p>
            </div>
          </div>
          <div
            ref={card2Ref}
            className={`card w-96 bg-base-200 shadow-xl mt-6 ${
              collapsed.card2 ? 'collapse' : 'expand'
            }`}
          >
            <div className="card-body">
              <h2 className="card-title">Scalable Infrastructure</h2>
              <p>
                Built with scalability in mind, Hive can adapt to your growing needs, making it future-proof.
              </p>
            </div>
          </div>
          <div
            ref={card3Ref}
            className={`card w-96 bg-base-200 shadow-xl mt-6 ${
              collapsed.card3 ? 'collapse' : 'expand'
            }`}
          >
            <div className="card-body">
              <h2 className="card-title">Unmatched Reliability</h2>
              <p>
                With redundant systems and robust architecture, Hive ensures maximum uptime and reliability.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Hero01;
