import Header from './../components/Header.js';
import Footer from './../components/Footer.js';

export default function Err404() {
    return (
    <>
        <Header />
        <main className="grid min-h-screen place-items-center bg-base-300 px-6 py-24 sm:py-32 lg:px-8">
          <div className="text-center">
            <p className="text-base font-semibold text-blue">404</p>
            <h1 className="mt-4 text-3xl font-bold tracking-tight text-neutral-content sm:text-5xl">Page not found</h1>
            <p className="mt-6 text-base leading-7 text-neutral-content">Sorry, we couldn’t find the page you’re looking for.</p>
            <div className="mt-10 flex items-center justify-center gap-x-6">
              <a
                href="https://malachi.technology"
                className="rounded-md btn btn-primary"
              >
                Go back home
              </a>
              <a href="https://help.malachi.technology/index.php" className="text-sm font-semibold text-neutral-content">
                Contact support <span aria-hidden="true">&rarr;</span>
              </a>
            </div>
          </div>
        </main>
        <Footer />
      </>
    )
  }