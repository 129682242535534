import { Routes, Route } from "react-router-dom";
import App from "./App.js";
import Err404 from "./ErrPages/404.js";
import Blog from "./Blog.js";
import About from "./AboutJS/About.js";
import Product from "./ProductJS/Product.js"; // Import the Product page

function SPAHandler() {
    return (
        <div className="SPAHandler">
            <Routes>
                <Route path="/" element={<App />} />
                <Route path="404" element={<Err404 />} />
                <Route path="blog" element={<Blog />} />
                <Route path="about" element={<About />} />
                <Route path="Product" element={<Product />} /> {/* Add Product route */}
            </Routes>
        </div>
    );
}

export default SPAHandler;
