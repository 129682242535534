import React, { useState, useEffect } from 'react';
import './styles.css';

function Hero02() {
  // State to track whether the header should collapse
  const [isCollapsed, setIsCollapsed] = useState(false);

  // Function to handle scroll and collapse the hero section
  const handleScroll = () => {
    if (window.scrollY > 50) {
      setIsCollapsed(true);
    } else {
      setIsCollapsed(false);
    }
  };

  // Add scroll event listener
  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div className={`Hero02 py-15 ${isCollapsed ? 'collapsed' : ''}`}>
      <div
        className={`hero min-h-screen ${isCollapsed ? 'h-32' : 'h-96'} bg-base-100 transition-all duration-300`}
        style={{
          backgroundImage: "url('/mtech-bg.png')",
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
        }}
      >
        <div className={`hero-overlay ${isCollapsed ? 'bg-opacity-20' : 'bg-opacity-10'} blur`}></div>
        <div className="hero-content items-center text-center text-neutral-content">
          <div className="max-w-md">
            <h1 className={`mb-5 text-3xl ${isCollapsed ? 'text-3xl' : 'text-5xl'} font-bold`}>
              The Journey to Hive
            </h1>
            <p className="mb-5">
              Building Hive has been an exciting journey filled with challenges and achievements. From conceptualization to execution, we've dedicated ourselves to creating a server that stands out in performance and innovation.
            </p>
            <a href="/About" className="btn btn-primary">
        View Our Journey
      </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Hero02;
